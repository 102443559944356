










































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import html2canvas from "html2canvas";

import JSZip from "jszip";

// @ts-ignore
import confetti from "canvas-confetti";
import AvatarCreatorMixin from "./creator.mixin";
import { RenderType, GenderType } from "./interface/avatar.interface";

@Component({
  components: {
    ExportLoading: () => import("@/components/ExportLoading.vue"),
  },
  mixins: [],
})
export default class AvatarCreator extends Mixins(AvatarCreatorMixin) {
  private width = 280;
  private height = 280;
  private exporting = false;
  private ammount = 100;
  private showMask = false;
  private progress = 0;

  private backgroundColor = "#fff";
  private borderRadius = "12px";

  private mask: any = null;
  private showWechatGroupQrCard = false;

  private svgRaw = "";

  private exportTypes = [
    { label: "SVG", value: "svg" },
    { label: "PNG", value: "png" },
  ];

  mounted() {
    this.createAvatar();
  }

  /**
   * 生成头像
   */
  private async createAvatar(disableConfetti = false) {
    const svgRaw = await this.createOne(
      {
        size: this.width,
        renderer: RenderType.SVG,
        amount: 1,
        gender: GenderType.UNSET,
      },
      disableConfetti
        ? () => {}
        : () => {
            this.applyConfettiAnimation();
          }
    );

    this.svgRaw = svgRaw;

    if (!disableConfetti) {
      // 获取背景颜色
      const tempWrapper = document.createElement("div");
      tempWrapper.innerHTML = svgRaw;
      const bgGroup = tempWrapper.querySelector("#gaoxia-avatar-Background");
      if (bgGroup) {
        const bgRect = bgGroup.querySelector("rect");
        if (bgRect)
          this.backgroundColor = bgRect.getAttribute("fill") || "#fff";
      }
    } else {
      this.backgroundColor = "#fff";
    }
  }

  /**
   * 截取
   */
  async capture() {
    this.exporting = true;
    this.borderRadius = "0";
    this.$nextTick(async () => {
      const dom: HTMLElement = document.querySelector(
        "#avatar-preview"
      ) as HTMLElement;
      const canvas = await html2canvas(dom, {
        logging: false,
        scale: window.devicePixelRatio,
        width: this.width,
        height: this.height,
      });
      const a = document.createElement("a");
      a.href = canvas.toDataURL();
      a.download = "avatar.png";
      a.click();
      this.exporting = false;
      this.borderRadius = "12px";
    });
  }

  /**
   * 批量制作
   */
  async superMake() {
    this.$emit("multiple-start");
    setTimeout(() => {
      this.exporting = true;
      this.showMask = true;
      let { ammount } = this;
      const max = 10000;
      ammount = ammount > max ? max : ammount < 0 ? 1 : ammount;
      this.ammount = ammount;
      this.progress = 0;

      const zip = new JSZip();
      this.borderRadius = "0";

      this.$nextTick(async () => {
        for (let i = 0; i < ammount; i++) {
          this.createAvatar(true);
          const dom: HTMLElement = document.querySelector(
            "#avatar-preview"
          ) as HTMLElement;

          const canvas = await html2canvas(dom, {
            logging: false,
            scale: window.devicePixelRatio * 2,
            width: this.width,
            height: this.height,
            ignoreElements: this.exportIgnoreMiddleware as any,
          });

          const dataUrl = canvas
            .toDataURL()
            .replace("data:image/png;base64,", "");
          zip.file(`${i + 1}.png`, dataUrl, { base64: true });
          this.progress = i + 1;
        }
        const base64 = await zip.generateAsync({ type: "base64" });
        const a = document.createElement("a");
        a.href = "data:application/zip;base64," + base64;
        a.download = "avatar.zip";
        a.click();
        this.exporting = false;
        this.$emit("multiple-end");
        this.showMask = false;
      });
    }, 0);
  }

  /**
   * 从一个数组中随机获取
   */
  randomSelectWithWeight<T>(
    type: T,
    arr: Record<string, any>[],
    valueKey = "id",
    weightKey = "weight"
  ): T {
    const store: Array<T> = [];
    arr.forEach((el) => {
      const value = el[valueKey];
      if (Object.prototype.toString.call(el[weightKey]) !== "[object Number]") {
        throw Error("weight is not a Number");
      } else {
        const weight: number = el[weightKey] || 1;
        for (let i = 0; i < weight; i++) store.push(value);
      }
    });
    const randIndex =
      parseInt((Math.random() * store.length * 10000).toFixed(0)) %
      store.length;
    return store[randIndex];
  }

  exportIgnoreMiddleware(el: HTMLElement) {
    if (el && el.getAttribute("class")) {
      const ignores = ["export-loading"];
      if (
        el &&
        ignores.some(
          (className) =>
            [el.getAttribute("class") || ""].indexOf(className) > -1
        )
      )
        return true;
    }
    return false;
  }

  /**
   * 绘制彩带动画
   */
  private applyConfettiAnimation() {
    const btn = document.querySelector("#refresh-btn");
    if (!btn) return;
    const rect = btn.getBoundingClientRect();
    const { clientWidth, clientHeight } = document.body;
    const centerOfBtnX = rect.left + rect.width / 2;
    const centerOfBtnY = rect.top + rect.height / 2;
    const centerOfBtnXPercent = centerOfBtnX / clientWidth;
    const centerOfBtnYPercent = centerOfBtnY / clientHeight;

    const _confetti = function(opt = {}) {
      confetti({
        particleCount: Math.floor(100 + Math.random() * 100),
        angle: 80,
        spread: 155, // 最大角度
        startVelocity: 50, // 最大距离
        decay: 0.9, // 减速： [0, 1]
        gravity: 3,
        ticks: 200, // 移动次数
        origin: {
          x: centerOfBtnXPercent,
          y: centerOfBtnYPercent,
        },
        colors: [
          "#F4D03F",
          "#E20650",
          "#1F618D",
          "#3498DB",
          "#E74C3C",
          "#48C9B0",
          "#34495E",
          "#31FBE0",
        ],
        shapes: ["square"],
        scalar: 1,
        zIndex: clientWidth > 400 ? 0 : 100,

        ...opt,
      });
    };
    _confetti({
      scalar: 1.4,
    });
    _confetti({
      particleCount: 50,
      // angle: 80,
      spread: 65, // 最大角度
      startVelocity: 60, // 最大距离
      gravity: 2,
    });
    _confetti({
      particleCount: 20,
      angle: 80,
      spread: 45,
      startVelocity: 40,
      colors: [
        "#7b5cff",
        "#6245e0",
        "#b3c7ff",
        "#8fa5e5",
        "#5c86ff",
        "#345dd1",
      ],
      scalar: 1.2,
    });
  }

  private maskClickListener = (e: Event) => this.toggleWechatGroupQrCard(false);
  private toggleWechatGroupQrCard(show: boolean) {
    this.showWechatGroupQrCard = show;
    // 移除mask
    try {
      if (this.mask) {
        this.mask.removeEventListener("click", this.maskClickListener);
        document.body.removeChild(this.mask);
        this.mask = null;
      }
    } catch (err) {
      console.log("Error to remove mask", err);
    }
    if (show) {
      const mask = document.createElement("div");

      mask.setAttribute(
        "style",
        `
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,.4);
        left: 0;
        top: 0;
        z-index: 1000;
        backdrop-filter: saturate(180%) blur(20px);
        display: flex;
        justify-content: center;
        align-items: center;
      `
      );
      const card = document.createElement("div");
      card.setAttribute(
        "style",
        `
        width: 300px;
        height: 400px;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0px 10px 20px rgba(0,0,0,.1), 0px 20px 40px rgba(0,0,0,0);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
      `
      );

      const img = document.createElement("img");
      img.setAttribute("src", require("@/assets/wechat-qr.png"));
      img.setAttribute("loading", "lazy");
      img.setAttribute(
        "style",
        `
        width: 100%;
      `
      );

      const description = document.createElement("div");
      const title = document.createElement("div");
      const content = document.createElement("div");
      title.innerText = "Wave";
      content.innerHTML =
        "🔧 面向未来的数据可视化分析工具。<br />💬 分享世界精彩的可视化内容。";
      title.setAttribute("style", "font-size: 1.3rem;font-weight: bold;");
      content.setAttribute("style", "font-size: 0.8rem;");
      description.appendChild(title);
      description.appendChild(content);

      card.appendChild(img);
      card.appendChild(description);
      mask.appendChild(card);

      mask.addEventListener("click", this.maskClickListener);
      this.mask = mask;
      document.body.appendChild(mask);
    }
  }
}
